import { type Key } from 'react-aria-components'
import { useLanguage } from '#app/contexts/language-context.tsx'
import { Icon } from '../ui/icon'
import {
	ComboBox,
	ComboBoxButton,
	ComboBoxContent,
	ComboBoxInput,
} from '../ui-new/combobox'
import { iconButtonVariants } from '../ui-new/icon-button'
import { Label } from '../ui-new/label'
import { type AreaOption, ComboBoxClearButton, ListItem, NoResults } from './common'

export const ComboBoxDesktop = ({
	className,
	inputClassName,
	label,
	value,
	name,
	filterValue,
	filteredItems,
	disabled,
	required,
	invalid,
	setFilterValue,
	onChange
}: {
	className?: string;
	inputClassName?: string;
	label?: string;
	value?: AreaOption;
	name?: string;
	filterValue: string;
	filteredItems: AreaOption[];
	disabled?: boolean;
	required?: boolean;
	invalid?: boolean;
	setFilterValue: (value: string) => void;
	onChange?: (event: { field: string; value?: AreaOption }) => void;
}) => {
	const { t } = useLanguage()

	const handleInputChange = (value: string) => {
		setFilterValue(value)
	}

	const handleSelectionChange = (key: Key | null) => {
		if (onChange && typeof onChange === 'function') {
			const item = filteredItems.find((itm) => itm.id === key?.toString())
			onChange({ field: name || 'searchTerm', value: item || undefined })
		}
	}


	return (
		<div className={className}>
			<ComboBox
				defaultSelectedKey={value?.id}
				inputValue={filterValue}
				items={filteredItems}
				id="autocomplete-search"
				aria-label="Select search area"
				// className={cx('', className)}
				isDisabled={disabled}
				isRequired={required}
				isInvalid={invalid}
				onInputChange={handleInputChange}
				onSelectionChange={handleSelectionChange}
				allowsEmptyCollection
			>
				<div className="relative">
					{label && (
						<Label
							aria-label="search-area"
							className="absolute left-1 top-2 z-10 origin-[0] -translate-y-4 scale-75 transform bg-background px-2 text-sm text-muted-foreground"
						>
							{label}
						</Label>
					)}
					<ComboBoxInput
						className={`h-10 rounded-md border border-input bg-background px-3 py-2 pl-10 text-base ${invalid ? 'border-2' : ''} ${inputClassName ?? ''}`}
						placeholder={t('Common', 'e.g. Paphos or Egkomi, Nicosia')}
						data-testid="autocomplete-input"
					/>
					<div className="absolute inset-y-0 right-0 mr-1 flex items-center space-x-2 p-1">
						<ComboBoxClearButton disabled={disabled} />
						<ComboBoxButton
							className={iconButtonVariants({
								variant: 'subtle',
								size: 'sm',
								className: 'ml-auto shrink-0',
							})}
						>
							<Icon name="chevron-down" />
						</ComboBoxButton>
					</div>
					<div className="absolute inset-y-0 left-0 flex items-center p-1">
						<Icon
							name="magnifying-glass"
							aria-hidden
							className="ml-2 h-5 w-5 text-gray-600 forced-colors:text-[ButtonText]"
						/>
					</div>
				</div>

				<ComboBoxContent
					placement="bottom"
					// shouldFlip={false}
					renderEmptyState={() => <NoResults query={filterValue} />}
				>
					{(item: AreaOption) => <ListItem key={item.id} itm={item} />}
				</ComboBoxContent>
			</ComboBox>

			{name && value && (
				<>
					<input
						type="hidden"
						aria-label="searchValueCity"
						name={`${name}-city`}
						defaultValue={value.city}
					/>
					<input
						type="hidden"
						aria-label="searchValueArea"
						name={`${name}-area`}
						defaultValue={value.area}
					/>
				</>
			)}

			{invalid && (
				<div className="search-area-error ml-1 pt-1 text-sm font-normal text-red-500">
					{t('Common', 'Please provide a search area')}
				</div>
			)}
		</div>
	)
}