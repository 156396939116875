import { useEffect, useMemo, useState } from 'react'
import { useLanguage } from '#app/contexts/language-context.tsx'
import { useMediaQuery } from '#app/hooks/use-media-query.tsx'
import { detectLanguage, removePunctuation } from '#app/utils/misc.tsx'
import { type AreaOption } from './common'
import { ComboBoxDesktop } from './desktop'
import { ComboBoxMobile } from './mobile'

interface AutoCompleteProps {
	value?: AreaOption
	label?: string //Search area
	className?: string
	inputClassName?: string
	required?: boolean
	name?: string
	invalid?: boolean
	disabled?: boolean
	items: AreaOption[]
	onChange?: (event: { field: string; value?: AreaOption }) => void
}

export function SearchAreaInput({
	value,
	label,
	className,
	inputClassName,
	required,
	name,
	invalid,
	disabled,
	items,
	onChange,
}: AutoCompleteProps) {
	let { isEnglish, currentLang } = useLanguage()
	const isDesktop = useMediaQuery('(min-width: 768px)')
	const [filterValue, setFilterValue] = useState(
		!value ? '' : value[isEnglish ? 'label' : 'labelGR'],
	)

	useEffect(() => {
		if (value) {
			if (currentLang === 'en') {
				setFilterValue(value.label)
			} else {
				setFilterValue(value.labelGR)
			}
		}
	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [currentLang])

	let filteredItems = useMemo(() => {
		if (filterValue === '')
			return (items || [])
				.slice(0, 5)
				.sort((a, b) => (a.label > b.label ? 1 : -1))

		const isGreek = detectLanguage(filterValue) === 'Greek'

		const results: AreaOption[] = (items || []).filter((area) => {
			return removePunctuation(isGreek ? area.labelGR : area.label)
				.toLowerCase()
				.includes(removePunctuation(filterValue).toLowerCase())
		})

		if (results.length == 0) return []

		const sortedResults = results.sort((a, b) => {
			// Step 1: Sort by 'type' ('city' first)
			if (a.type === 'city' && b.type !== 'city') {
				return -1 // 'a' should come before 'b' (because 'a' is 'city')
			}
			if (a.type !== 'city' && b.type === 'city') {
				return 1 // 'b' should come before 'a' (because 'b' is 'city')
			}

			// Step 2: If both have the same 'type', sort alphabetically by 'label'
			return a.label.localeCompare(b.label)
		})

		return sortedResults.slice(0, 10).map((area) => {
			return {
				...area,
				isGreek
			}
		})
	}, [filterValue, items])

	if (isDesktop) return (
		<ComboBoxDesktop
			className={className}
			inputClassName={inputClassName}
			label={label}
			value={value}
			name={name}
			filterValue={filterValue}
			filteredItems={filteredItems}
			disabled={disabled}
			required={required}
			invalid={invalid}
			onChange={onChange}
			setFilterValue={setFilterValue}
		/>
	)

	//Special mobile component
	return (
		<ComboBoxMobile
			className={className} 
			inputClassName={inputClassName}
			label={label}
			value={value}
			name={name}
			filterValue={filterValue}
			filteredItems={filteredItems}
			disabled={disabled}
			required={required}
			invalid={invalid}
			onChange={onChange}
			setFilterValue={setFilterValue}
		/>
	)
}
