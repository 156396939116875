import { useState } from 'react'
import { ListBox, type Key, type Selection } from 'react-aria-components'
import { useLanguage } from '#app/contexts/language-context.tsx'
import { type AreaOption } from '#app/utils/providers/autocomplete-data.ts'
import { Icon } from '../ui/icon'
import { iconButtonVariants } from '../ui-new/icon-button'
import { Label } from '../ui-new/label'
import {
	SearchField,
	SearchFieldClearButton,
	SearchFieldInput,
} from '../ui-new/search-field'
import { Sheet } from '../ui-new/sheet'
import { ListItem, type ListItemItm, NoResults } from './common'

export const ComboBoxMobile = ({
    className,
    label,
    name,
    value,
    filterValue,
    filteredItems,
    inputClassName,
    disabled,
    required,
    invalid,
    setFilterValue,
    onChange
}: {
    className?: string;
    label?: string;
    name?: string;
    value?: AreaOption;
    filterValue: string;
    filteredItems: AreaOption[];
    inputClassName?: string;
    disabled?: boolean;
    required?: boolean;
    invalid?: boolean;
    setFilterValue: (value: string) => void;
    onChange?: (event: { field: string; value?: AreaOption }) => void
}) => {
    const [drawerOpen, setDrawerOpen] = useState(false)
    const [selectedKeys, setSelectedKeys] = useState<Selection>(new Set<Key>(value ? [value.id] : []));
    const [selected, setSelected] = useState<AreaOption | undefined>(value);
    const { t, isEnglish } = useLanguage()

    const handleClearButton = () => {
        setFilterValue('')
        setSelectedKeys(new Set())
        setSelected(undefined)

        if (onChange && typeof onChange === 'function') {
            onChange({ field: name || 'searchTerm', value: undefined })
        }
    }

    const handleListboxChange = (keys:Selection) => {
        setSelectedKeys(keys)
        const selected = Array.from(keys)
        const item = filteredItems.find((itm) => itm.id === selected[0])
        setFilterValue(item?.[isEnglish ? 'label' : 'labelGR' ] || '')

        if (onChange && typeof onChange === 'function') {
            onChange({ field: name || 'searchTerm', value: item || undefined })
            setSelected(item || undefined)
        }

        if (item) {
            setDrawerOpen(false)
        }
    }

    const handleShowDrawer = (e:any) => {
        e.preventDefault()

        setDrawerOpen(true)
    }

    return (
        <>
            <SearchField className={className} value={selected ? selected[isEnglish ? 'label' : 'labelGR'] : ''} isInvalid={invalid} isDisabled={disabled} isRequired={required} isReadOnly={true} aria-labelledby='autocomplete-search-wrapper'>
                <div className="relative">
                    {label && (
                        <Label
                            id="autocomplete-search-wrapper"
                            aria-label="search-area"
                            className="absolute left-1 top-2 z-10 origin-[0] -translate-y-4 scale-75 transform bg-background px-2 text-sm text-muted-foreground"
                        >
                            {label}
                        </Label>
                    )}
                    
                    <div
                        aria-hidden="true"
                        className="pointer-events-none absolute flex h-full w-10 items-center justify-center text-slate-500 dark:text-slate-400"
                    >
                        <Icon
                            name="magnifying-glass"
                            aria-hidden
                            className="ml-2 h-5 w-5 text-gray-600 forced-colors:text-[ButtonText]"
                        />
                    </div>
                    <SearchFieldInput 
                        className={`h-10 rounded-md border border-input bg-background px-3 py-2 pl-10 text-base ${invalid ? 'border-2' : ''} ${inputClassName ?? ''}`}
                        placeholder={t('Common', 'e.g. Paphos or Egkomi, Nicosia')}
                        data-testid="autocomplete-input" 
                        autoComplete="off"
                        readOnly
                        onClick={handleShowDrawer}
                    />
                    <div className="absolute inset-y-0 right-0 mr-1 flex items-center space-x-2 p-1">
                        <div
                            aria-label='Show suggestions'
                            className={iconButtonVariants({
                                variant: 'subtle',
                                size: 'sm',
                                className: 'ml-auto shrink-0',
                            })}
                            onClick={handleShowDrawer}
                        >
                            <Icon name="chevron-down" />
                        </div>
                    </div>
                </div>

                {name && value && (
                    <>
                        <input
                            type="hidden"
                            aria-label="searchValueCity"
                            name={`${name}-city`}
                            defaultValue={value.city}
                        />
                        <input
                            type="hidden"
                            aria-label="searchValueArea"
                            name={`${name}-area`}
                            defaultValue={value.area}
                        />
                    </>
                )}

                {invalid && (
                    <div className="search-area-error ml-1 pt-1 text-sm font-normal text-red-500">
                        {t('Common', 'Please provide a search area')}
                    </div>
                )}
            </SearchField>
            <Sheet isOpen={drawerOpen} onClose={() => setDrawerOpen(false)}>
                <>
                    <SearchField value={filterValue} isInvalid={invalid} aria-labelledby='search-area-input-mobile'>
                        <div className="relative">
                            <Label id='search-area-input-mobile' className='sr-only'>{t("Common", "Enter a city or area")}</Label>
                            <div
                                aria-hidden="true"
                                className="pointer-events-none absolute flex h-full w-10 items-center justify-center text-slate-500 dark:text-slate-400"
                            >
                                <Icon
                                    name="magnifying-glass"
                                    aria-hidden
                                    className="ml-2 h-5 w-5 text-gray-600 forced-colors:text-[ButtonText]"
                                />
                            </div>
                            
                            <SearchFieldInput
                                aria-labelledby='search-area-input-mobile'
                                className={`h-10 rounded-md border border-input bg-background px-3 py-2 pl-10 text-base ${invalid ? 'border-2' : ''}`}
                                placeholder={t('Common', 'e.g. Paphos or Egkomi, Nicosia')}
                                autoComplete="off"
                                onChange={(e) => setFilterValue(e.target.value)}
                                data-testid="autocomplete-input" 
                            />
                            
                            <div className="absolute inset-y-0 right-0 flex items-center p-1">
                                <SearchFieldClearButton
                                    onPress={handleClearButton}
                                    variant="subtle"
                                    size="sm"
                                    aria-label="Clear search"
                                />
                            </div>	
                        </div>

                        {invalid && (
                            <div className="search-area-error ml-1 pt-1 text-sm font-normal text-red-500">
                                {t('Common', 'Please provide a search area')}
                            </div>
                        )}
                    </SearchField>

                    <ListBox
                        aria-label='Search input suggestions'
                        selectionMode='single'
                        disallowEmptySelection={false}
                        selectedKeys={selectedKeys}
                        onSelectionChange={handleListboxChange}
                        items={filteredItems}
                        renderEmptyState={() => <NoResults query={filterValue} />}
                        className="w-full h-full mt-2 space-y-2 overflow-y-scroll"
                    >
                        {(item:ListItemItm) => <ListItem className='p-1' itm={item} />}
                    </ListBox>
                </>
            </Sheet>
        </>
    )
}