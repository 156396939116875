import { useContext } from "react"
import { Button, ComboBoxStateContext } from "react-aria-components"
import { useLanguage } from "#app/contexts/language-context.tsx"
import { Icon } from "../ui/icon"
import { ComboBoxItem } from "../ui-new/combobox"
import { iconButtonVariants } from "../ui-new/icon-button"

export type AreaOption = {
    id: string,
    label:string, 
    labelGR:string, 
    city:string, 
    area:string,
    type?:string,
}

export interface ListItemItm extends AreaOption {
    isGreek?: boolean
}

export const ListItem = ({ className, itm }: { className?:string, itm: ListItemItm }) => {
    const { isEnglish } = useLanguage()

    return (
        <ComboBoxItem
            key={itm.id}
            id={itm.id}
            textValue={itm.isGreek || !isEnglish ? itm.labelGR : itm.label}
            data-testid="suggestion-option"
            className={className ?? ''}
        >
            <Icon
                name={itm?.type === 'city' ? 'city' : 'location-pin'}
                size="sm"
                className="mr-2"
            />
            {itm.isGreek || !isEnglish ? itm.labelGR : itm.label}
            <Icon
                name="check"
                size="sm"
                aria-hidden="true"
                strokeWidth="3"
                className="invisible group-selected:visible"
            />
        </ComboBoxItem>
    )
}

export const NoResults = ({ query }: { query: string }) => {
	return (
		<li className="space-between flex w-full p-4">No results for "{query}"</li>
	)
}

export const ComboBoxClearButton = ({ disabled }: { disabled?: boolean }) => {
	let state = useContext(ComboBoxStateContext)

	if (!state || !(state.selectedKey || state.inputValue)) return <></>

	return (
		<Button
			// Don't inherit default Button behavior from ComboBox.
			slot={null}
			className={iconButtonVariants({
				variant: 'subtle',
				size: 'sm',
				className: 'ml-auto shrink-0',
			})}
			aria-label="Reset"
			onPress={() => {
				state?.setSelectedKey(null)
				state?.setInputValue('')
			}}
			isDisabled={disabled}
		>
			<Icon name="cross-1" />
		</Button>
	)
}