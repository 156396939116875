import {
	animate,
	AnimatePresence,
	motion,
	useMotionTemplate,
	useMotionValue,
	useMotionValueEvent,
	useTransform,
} from 'framer-motion'
import { useEffect, useRef, useState } from 'react'
import {
	Button,
	Dialog,
	Heading,
	Modal,
	ModalOverlay,
} from 'react-aria-components'
import { Icon } from '../ui/icon'
import { iconButtonVariants } from './icon-button'

const MotionModal = motion(Modal)
const MotionModalOverlay = motion(ModalOverlay)

const inertiaTransition = {
	type: 'inertia' as const,
	bounceStiffness: 300,
	bounceDamping: 40,
	timeConstant: 300,
}

const staticTransition = {
	duration: 0.5,
	ease: [0.32, 0.72, 0, 1],
}

const SHEET_MARGIN = 34
const SHEET_RADIUS = 12

export function Sheet({
	isOpen,
	onClose,
	title,
	children,
}: {
	isOpen: boolean
	onClose: () => void
	title?: string
	children: React.ReactNode
}) {
	const [h, setH] = useState(600)
	const rootRef = useRef<HTMLElement | null>(null)

	useEffect(() => {
		if (typeof window !== 'undefined') {
			setH(window.innerHeight - SHEET_MARGIN)
			rootRef.current = document.body.firstChild as HTMLElement
		}
	}, [])

	const y = useMotionValue(h)
	const bgOpacity = useTransform(y, [0, h], [0.4, 0])
	const bg = useMotionTemplate`rgba(0, 0, 0, ${bgOpacity})`

	const bodyScale = useTransform(y, [0, h], [1, 1])
	const bodyTranslate = useTransform(
		y,
		[0, h],
		[SHEET_MARGIN - SHEET_RADIUS, 0],
	)
	const bodyBorderRadius = useTransform(y, [0, h], [SHEET_RADIUS, 0])

	useMotionValueEvent(bodyScale, 'change', (v) => {
		if (rootRef.current) rootRef.current.style.scale = `${v}`
	})

	// useMotionValueEvent(bodyTranslate, 'change', (v) => {
	// 	if (rootRef.current) rootRef.current.style.translate = `0 ${v}px`
	// })

	useMotionValueEvent(bodyBorderRadius, 'change', (v) => {
		if (rootRef.current) rootRef.current.style.borderRadius = `${v}px`
	})

	return (
		<AnimatePresence>
			{isOpen && (
				<MotionModalOverlay
					isOpen
					onOpenChange={onClose}
					className="fixed inset-0 z-[100]"
					style={{ backgroundColor: bg as any }}
				>
					<MotionModal
						className="absolute bottom-0 w-full rounded-t-xl bg-background shadow-lg will-change-transform"
						initial={{ y: h }}
						animate={{ y: 0 }}
						exit={{ y: h }}
						transition={staticTransition}
						style={{
							y,
							top: SHEET_MARGIN,
							paddingBottom:
								typeof window !== 'undefined' ? window.screen.height : 0,
						}}
						drag="y"
						dragConstraints={{ top: 0 }}
						onDragEnd={(e, { offset, velocity }) => {
							if (offset.y > h * 0.75 || velocity.y > 10) {
								onClose()
							} else {
								animate(y, 0, { ...inertiaTransition, min: 0, max: 0 })
							}
						}}
					>
						<div className="mx-auto mt-2 h-1.5 w-12 rounded-full bg-gray-400" />
						<Dialog className="outline-hidden px-4 pb-4" aria-label="sheet-content">
							<div className={`mb-1 flex items-center ${title ? 'justify-between' : 'justify-end'}`}>
								{title && (
									<Heading className="text-3xl font-semibold">{title}</Heading>
								)}
								<Button
                                    className="text-sm font-semibold outline-hidden rounded-sm bg-transparent border-none focus-visible:ring-3"
                                    onPress={onClose}
                                >
                                    Close
                                </Button>
							</div>

							{children}
						</Dialog>
					</MotionModal>
				</MotionModalOverlay>
			)}
		</AnimatePresence>
	)
}
